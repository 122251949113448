// Our main CSS
import '../css/app.css'

/**
 * Vue is ready for you, if you need it.
 */

/**
 * The following block of code automatically registers your Vue components.
 * It will recursively scan this directory for the Vue components and
 * automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *
 * Feel free to remove this block if you don't like black magic fuckery,
 * or you want to register your Vue components manually.
 */
/*
 *** https://v3.vuejs.org/
 */
// interface ImportedModule {
//   name: string
//   default: object
// }
// const vueSingleFileComponents = import.meta.glob<ImportedModule>('./**/*.vue', {
//   eager: true
// })

// import { createApp } from 'vue'
// const app = createApp({})

// for (const path in vueSingleFileComponents) {
//   const importedModule = vueSingleFileComponents[String(path)]
//   const name = path.split('/').pop()?.split('.')[0]
//   if (name !== undefined && typeof name === 'string') {
//     app.component(name, importedModule.default)
//   }
// }
// app.mount('#vue-app')

/**
OR:

Load a js-module dynamically, if the parent element exists, in this example, a vue component. (The js-file wont load if html element with id #ExampleVueComponentWrapper does not exists)
*/

/**
const ExampleVueComponentWrapper = document.getElementById(
  'ExampleVueComponentWrapper'
)
if (ExampleVueComponentWrapper && ExampleVueComponentWrapper !== null) {
  import('./ExampleVueComponent/index.js')
    .then((module) => {
      module.default(ExampleVueComponentWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}
*/

/**
 * 🦄: Now do your magic.
 */

const DocumentsArchiveWrapper = document.getElementById('DocumentsArchiveWrapper')
if (DocumentsArchiveWrapper && DocumentsArchiveWrapper !== null) {
  import('./DocumentsArchive/index.ts')
    .then((module) => {
      module.default(DocumentsArchiveWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}

const NewsArchiveWrapper = document.getElementById('NewsArchiveWrapper')
if (NewsArchiveWrapper && NewsArchiveWrapper !== null) {
  import('./NewsArchive/index.ts')
    .then((module) => {
      module.default(NewsArchiveWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}

if (document.querySelector('#page-top-btn')) {
  import('./page-top-btn').then((module) => {
    module.default()
  })
}

if (document.querySelectorAll('.video-card').length > 0) {
  import('./video-card').then((module) => {
    module.default()
  })
}

if (document.querySelectorAll('.slider').length > 0) {
  import('./slider').then((module) => {
    const sliders = document.querySelectorAll('.slider')
    sliders.forEach((slider) => {
      module.default(slider)
    })
  })
}

if (document.querySelectorAll('.anchor-link').length > 0) {
  import('./anchor-link').then((module) => {
    module.default()
  })
}

if (document.querySelectorAll('.accordions').length > 0) {
  import('./accordions').then((module) => {
    module.default()
  })
}

if (document.querySelectorAll('.merits').length > 0) {
  import('./accordions').then((module) => {
    module.default()
  })
}

/***** Mobile menu *****/
const mobileMenuBtn = document.querySelector('.mobile-menu-btn')
const mobileMenu = document.querySelector('nav')
// const mobileMenu = document.querySelector('.mobile-menu-wrapper')

if (mobileMenuBtn) {
  mobileMenuBtn?.addEventListener('click', () => {
    mobileMenuBtn?.classList.toggle('active')
    mobileMenu?.classList.toggle('open')
    document.body.classList.toggle('overflow-hidden')
  })

  const parentLinks = mobileMenu?.querySelectorAll('.mobile-menu>ul>.menu-item-has-children>a')

  parentLinks?.forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault()
      const submenu = link.nextElementSibling
      submenu?.classList.toggle('open')
    })
  })
}

function isSafari() {
  const ua = navigator.userAgent.toLowerCase()
  return ua.indexOf('safari') > -1 && ua.indexOf('chrome') == -1
}

if (isSafari()) {
  const sidebarWrapper = document.querySelectorAll('.sidebar-wrapper') as NodeListOf<HTMLElement>

  sidebarWrapper?.forEach(wrapper => {
    const slider = wrapper.querySelector('.slider') as HTMLElement

    if (slider) {
      slider.style.overflow = 'hidden'
    }
  })
}
